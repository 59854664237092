<template>
  <div class="withdrawal-method">
    <Container
      class="withdrawal-method__sticky"
      :preloader="preloader"
    >
      <template #content>
        <p class="withdrawal-method__sticky__label">
          {{ $t('views.default.profile.withdrawal') }}
        </p>

        <table>
          <thead>
            <tr>
              <th>
                {{ $t('views.default.profile.type') }}
              </th>

              <th
                v-if="!$store.state.window.to768"
              >
                {{ $t('views.default.profile.wallet') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in _wallets"
              :key="item.wallet"
            >
              <td>
                {{ item.currency.name }}
              </td>

              <td>
                {{ item.address }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="withdrawal-method__sticky__bottom">
          <div class="withdrawal-method__sticky__bottom__grid">
            <BlueButton
              style="max-width: 255px;"
              class="light"
              :text="$t('views.default.profile.delete-wallet')"
              @click="$pop.up('deleteWallet')"
            />

            <BlueButton
              style="max-width: 255px;"
              :text="$t('views.default.profile.add-wallet')"
              @click="$pop.up('withdrawalMethods')"
            />
          </div>
        </div>
      </template>
    </Container>
  </div>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    Container,
    BlueButton
  },
  data () {
    return {
      preloader: true
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    _wallets () {
      return this.$store.state.withdrawal.list
    }
  },
  methods: {
    async fetch () {
      try {
        this.$store.commit(
          'WITHDRAWAL_SET_LIST', (await this.$axios.get('private/wallets')).data.data)
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.withdrawal-method {
  position: relative;
  height: 100%;

  @media screen and (max-width: 1440px) {
    width: 100%;
  }

  &__sticky {
    position: sticky;
    top: 20px;
    max-width: 430px;
    min-width: 430px;
    padding: 30px 20px 20px;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    overflow: hidden;

    @media screen and (max-width: 1440px) {
      position: static;
      width: 100%;
      max-width: 100%;
      min-width: 0;
    }

    @media screen and (max-width: 768px) {
      padding: 20px 15px;
    }

    &__label {
      font-size: 18px;
      line-height: 100%;
      font-family: SemiBold;
      color: rgba(var(--text-color-light-gray));
    }

    table {
      width: 100%;
      margin: 20px 0;
      border-spacing: 0;

      thead {
        tr {
          th {
            padding-bottom: 8px;
            border-bottom: 1px solid rgba(var(--border-line-color));
            font-size: 12px;
            line-height: 100%;
            color: #787C8D;
            text-align: left;

            &:first-child {
              text-align: left !important;
            }

            &:last-child {
              text-align: right;
            }
          }
        }
      }

      tbody {
        tr {
          @media screen and (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid rgba(var(--border-line-color));
          }

          td {
            padding: 15px 0;
            border-bottom: 1px solid rgba(var(--border-line-color));
            font-family: Medium;
            line-height: 100%;

            @media screen and (max-width: 768px) {
              width: 100%;
              border: none !important;
            }

            &:first-child {
              max-width: 130px;
              font-size: 12px;
              line-height: 120%;
              color: rgba(var(--text-color-light-gray));
              white-space: wrap;

              @media screen and (max-width: 1440px) {
                width: 100%;
              }

              @media screen and (max-width: 768px) {
                text-align: left;
                padding: 15px 0 0 0;
              }
            }

            &:last-child {
              width: calc(430px - 170px);
              max-width: calc(430px - 170px);
              font-size: 14px;
              text-align: right;
              color: rgb(var(--color-dark));
              overflow-wrap: break-word;

              @media screen and (max-width: 1440px) {
                width: 100%;
                max-width: 480px;
              }

              @media screen and (max-width: 768px) {
                width: 100%;
                max-width: calc(100vw - (36px + 40px));
                text-align: left;
              }
            }
          }
        }
      }
    }

    &__bottom {
      width: 100%;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1440px) {
        justify-content: flex-start;
      }

      &__grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        @media screen and (max-width: 1440px) {
          width: auto;
          display: flex;
          align-items: center;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          flex-direction: column;
        }

        button {
          @media screen and (max-width: 1440px) {
            min-width: 250px;
          }

          @media screen and (max-width: 768px) {
            width: 100% !important;
            max-width: none !important;
          }
        }
      }
    }
  }
}
</style>
