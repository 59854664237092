<template>
  <div class="ci__ui">
    <div
      class="input-container"
      :class="{
        error: false
      }"
      @click="isDatePick = true"
    >
      <div class="input-container__content">
        {{ formatVisible(modelValue) }}
      </div>

      <p
        class="input-container__placeholder"
        :class="{
          active: modelValue && modelValue.toLocaleDateString().length > 0
        }"
      >
        {{ placeholder }}
      </p>

      <svg
        width="18" height="18"
        style="min-width: 18px; min-height: 18px"
        :class="{
          active: isDatePick
        }"
        viewBox="0 0 18 18" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1274_38320)">
          <path d="M16.0714 1.92857H14.1429V0.642869C14.1429 0.287816 13.855 0 13.5 0C13.1449 0 12.8571 0.287816 12.8571 0.642869V1.92857H5.14284V0.642869C5.14284 0.287816 4.85502 0 4.50001 0C4.14499 0 3.85714 0.287816 3.85714 0.642869V1.92857H1.92857C0.863449 1.92857 0 2.79202 0 3.85714V16.0714C0 17.1365 0.863449 18 1.92857 18H16.0714C17.1365 18 18 17.1365 18 16.0714V3.85714C18 2.79202 17.1365 1.92857 16.0714 1.92857ZM16.7143 16.0714C16.7143 16.4265 16.4264 16.7143 16.0714 16.7143H1.92857C1.57352 16.7143 1.2857 16.4265 1.2857 16.0714V7.71428H16.7143V16.0714ZM16.7143 6.42858H1.2857V3.85714C1.2857 3.50209 1.57352 3.21427 1.92857 3.21427H3.85714V4.49997C3.85714 4.85502 4.14496 5.14284 4.50001 5.14284C4.85506 5.14284 5.14288 4.85502 5.14288 4.49997V3.21427H12.8572V4.49997C12.8572 4.85502 13.145 5.14284 13.5 5.14284C13.8551 5.14284 14.1429 4.85502 14.1429 4.49997V3.21427H16.0715C16.4265 3.21427 16.7143 3.50209 16.7143 3.85714V6.42858H16.7143Z" fill="#787C8D"/>
          <path d="M5.1428 8.99998H3.8571C3.50205 8.99998 3.21423 9.2878 3.21423 9.64285C3.21423 9.99791 3.50205 10.2857 3.8571 10.2857H5.1428C5.49786 10.2857 5.78567 9.99791 5.78567 9.64285C5.78567 9.2878 5.49786 8.99998 5.1428 8.99998Z" fill="#787C8D"/>
          <path d="M9.6428 8.99998H8.3571C8.00205 8.99998 7.71423 9.2878 7.71423 9.64285C7.71423 9.99791 8.00205 10.2857 8.3571 10.2857H9.6428C9.99786 10.2857 10.2857 9.99791 10.2857 9.64285C10.2857 9.2878 9.99786 8.99998 9.6428 8.99998Z" fill="#787C8D"/>
          <path d="M14.1428 8.99998H12.8571C12.5021 8.99998 12.2142 9.2878 12.2142 9.64285C12.2142 9.99791 12.5021 10.2857 12.8571 10.2857H14.1428C14.4979 10.2857 14.7857 9.99791 14.7857 9.64285C14.7856 9.2878 14.4978 8.99998 14.1428 8.99998Z" fill="#787C8D"/>
          <path d="M5.1428 11.5714H3.8571C3.50205 11.5714 3.21423 11.8592 3.21423 12.2143C3.21423 12.5693 3.50205 12.8572 3.8571 12.8572H5.1428C5.49786 12.8572 5.78567 12.5693 5.78567 12.2143C5.78567 11.8592 5.49786 11.5714 5.1428 11.5714Z" fill="#787C8D"/>
          <path d="M9.6428 11.5714H8.3571C8.00205 11.5714 7.71423 11.8592 7.71423 12.2143C7.71423 12.5693 8.00205 12.8572 8.3571 12.8572H9.6428C9.99786 12.8572 10.2857 12.5693 10.2857 12.2143C10.2857 11.8592 9.99786 11.5714 9.6428 11.5714Z" fill="#787C8D"/>
          <path d="M14.1428 11.5714H12.8571C12.5021 11.5714 12.2142 11.8592 12.2142 12.2143C12.2142 12.5693 12.5021 12.8572 12.8571 12.8572H14.1428C14.4979 12.8572 14.7857 12.5693 14.7857 12.2143C14.7856 11.8592 14.4978 11.5714 14.1428 11.5714Z" fill="#787C8D"/>
          <path d="M5.1428 14.1429H3.8571C3.50205 14.1429 3.21423 14.4307 3.21423 14.7857C3.21423 15.1408 3.50205 15.4286 3.8571 15.4286H5.1428C5.49786 15.4286 5.78567 15.1407 5.78567 14.7857C5.78567 14.4306 5.49786 14.1429 5.1428 14.1429Z" fill="#787C8D"/>
          <path d="M9.6428 14.1429H8.3571C8.00205 14.1429 7.71423 14.4307 7.71423 14.7857C7.71423 15.1408 8.00205 15.4286 8.3571 15.4286H9.6428C9.99786 15.4286 10.2857 15.1408 10.2857 14.7857C10.2857 14.4307 9.99786 14.1429 9.6428 14.1429Z" fill="#787C8D"/>
          <path d="M14.1428 14.1429H12.8571C12.5021 14.1429 12.2142 14.4307 12.2142 14.7857C12.2142 15.1408 12.5021 15.4286 12.8571 15.4286H14.1428C14.4979 15.4286 14.7857 15.1408 14.7857 14.7857C14.7857 14.4307 14.4978 14.1429 14.1428 14.1429Z" fill="#787C8D"/>
        </g>
        <defs>
          <clipPath id="clip0_1274_38320">
            <rect width="18" height="18" fill="white"/>
          </clipPath>
        </defs>
      </svg>

      <Date
        v-if="isDatePick"
        class="input-container__date"
        v-model="_value"
        :is-range="false"
        :is-button="false"
        :is-min-date="false"
        v-click-outside="hideDatePick"
      />
    </div>

    <p
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(error) }}
    </p>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

import Date from '@/ui/inputs/Date'

export default {
  components: {
    Date
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    modelValue: {
      type: [String],
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isDatePick: false
    }
  },
  computed: {
    _value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    hideDatePick () {
      setTimeout(() => {
        this.isDatePick = false
      }, 250)
    },
    formatVisible (date) {
      if (date) {
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)

        return `${da}/${mo}/${ye}`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;

  .input-container__date {
    position: absolute;
    top: 100%;
    right: 0;
    // max-width: 100%;
    z-index: 10;
    // border: 1px solid red;
  }

  .input-container__content {
    position: relative;
    top: 22px;
    width: 100%;
    font-size: 16px;
    line-height: 100%;
    color: rgb(var(--color-dark));
    font-family: Medium;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    position: relative;
    top: 13px;

    path {
      transition: .2s;
    }

    &.active {
      path {
        fill: #2F57E9;
      }
    }
  }
}
</style>
