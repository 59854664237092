<template>
  <div class="ci__ui">
    <div
      v-if="disabled"
      class="ci__ui-disabled"
    />

    <div
      class="input-container flex auto-select"
      :class="{
        error: false
      }"
      @click.stop="view = true"
      v-click-outside="hide"
    >
      <input
        class="input-container__content"
        ref="input"
        v-model="data"
        @focus="view = true"
      >

      <div
        class="input-container__arrow"
        :class="{
          active: view
        }"
        @click.stop="view = !view, view ? $refs.input.focus() : $refs.input.blur()"
      >
        <svg
          width="14" height="9"
          style="min-width: 14px; min-height: 9px;"
          viewBox="0 0 14 9" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 1L7 7L1 1" stroke="#9CA3B8" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>

      <p
        class="input-container__placeholder"
        :class="{
          active: data.length > 0
        }"
      >
        {{ placeholder }}
      </p>

      <div
        v-if="view"
        class="input-container__list"
      >
        <template v-if="!loading">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="input-container__list__item"
            :class="{
              active: item.id === modelValue
            }"
            @click="
              item.id === modelValue ? null : $emit('update:modelValue', item.id),
              data = _eval(item),
              hide()
            "
          >
            {{ _eval(item) }}
          </div>
        </template>

        <div
          v-else
          class="input-container__list__loading"
        >
          <div class="input-container__list__loading__spiner" />
        </div>
      </div>
    </div>

    <p
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(error) }}
    </p>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    modelValue: {
      type: Number,
      default: null
    },
    link: {
      type: String,
      required: true
    },
    autoCompleteLink: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      mountFetch: false,
      data: '',
      bounceTimeOut: null,
      view: false,
      list: [],
      loading: false
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  watch: {
    data () {
      this.bounceFetch()
      this.$emit('update:modelValue', null)

      for (const i in this.list) {
        if (this.data === this._eval(this.list[i])) {
          this.$emit('update:modelValue', this.list[i].id)
        }
      }
    },
    link () {
      this.fetch()
    },
    autoCompleteLink () {
      this.fetch()
    },
    modelValue () {
      this.setValue()
    }
  },
  methods: {
    async fetch () {
      try {
        this.loading = true

        const resp = await this.$axios.get(
          this.link)
        if (resp.status === 200) {
          this.list = resp.data.data
          this.setValue()
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
        this.mountFetch = true
      }
    },
    bounceFetch () {
      if (this.data.length > 2) {
        clearTimeout(this.bounceTimeOut)
        this.bounceTimeOut = setTimeout(async () => {
          try {
            this.loading = true

            const resp = await this.$axios.get(
              `${this.autoCompleteLink}${this.data}`)
            if (resp.status === 200) {
              this.list = resp.data.data
            }
          } catch (err) {
            throw new Error(err)
          } finally {
            this.loading = false
          }
        }, 700)
      } else {
        clearTimeout(this.bounceTimeOut)
        this.fetch()
      }
    },
    setValue () {
      const select = this.modelValue

      if (select) {
        for (const i in this.list) {
          if (this.list[i].id === select) {
            this.data = this._eval(this.list[i])
          }
        }
      }
    },
    hide () {
      setTimeout(() => {
        this.view = false
      }, 100)
    },
    _eval (value) {
      // eslint-disable-next-line no-eval
      return eval(`value.${this.label}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.ci__ui {
  position: relative;

  .ci__ui-disabled {
    position: absolute;
    top: -10%;
    left: -2.5%;
    height: 120%;
    width: 105%;
    background: rgba(255, 255, 255, .4);
    z-index: 2;
    cursor: not-allowed;
  }
}

.input-container {
  position: relative;
  justify-content: space-between;
  padding: 5px 0 5px 7px !important;

  .input-container__content {
    position: relative;
    top: 14px;
    width: 100%;
    font-size: 16px;
    line-height: 100%;
    color: rgb(var(--color-dark));
    font-family: Medium;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .input-container__arrow {
    border-left: 1px solid rgba(var(--border-line-color));

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .input-container__list {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    max-height: 200px;
    width: 100%;
    padding: 4px;
    background: white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, .1);
    border-radius: 5px;
    overflow-y: auto;
    scrollbar-color: rgba(var(--primary-color), 1) rgba(var(--primary-color), .2);
    scrollbar-width: thin;
    z-index: 5;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: rgba(var(--primary-color), .2);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(var(--primary-color), 1);
      border-radius: 4px;
    }

    .input-container__list__item {
      padding: 12px 6px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 100%;
      color: rgb(var(--text-color-light-gray));
      font-family: Medium;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      transition: .2s;

      &:hover {
        color: rgb(var(--color-dark));
      }

      &.active {
        background: rgba(var(--primary-color), .2);
        color: rgb(var(--primary-color));
      }
    }

    .input-container__list__loading {
      display: flex;
      justify-content: center;

      .input-container__list__loading__spiner {
        height: 20px;
        width: 20px;
        min-width: 20px;
        border-top: 4px solid rgba(var(--text-color-light));
        border-right: 4px solid rgba(var(--text-color-light));
        border-bottom: 4px solid rgba(var(--text-color-light));
        border-left: 4px solid rgba(156, 163, 184, .2);
        border-radius: 50%;
        animation: spin 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
