<template>
  <UiPage
    :title="$t('app.page.profile')"
    :titleTemplate="$t('app.page.profile')"
    class="content-padding-mobile"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div
        class="info-block"
        v-html="$t('views.default.profile.info')"
      />

      <div class="content">
        <UserInfo />

        <WithdrawalMethod
          class="content__row-span"
        />

        <AdvertisingCampaigns />
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import UserInfo from '@/modules/profile/Info.vue'
import WithdrawalMethod from '@/modules/profile/WithdrawalMethod.vue'
import AdvertisingCampaigns from '@/modules/profile/advertising-campaigns/Stats.vue'

export default {
  components: {
    UiPage,
    BackButton,
    UserInfo,
    WithdrawalMethod,
    AdvertisingCampaigns
  }
}
</script>

<style lang="scss" scoped>
.info-block {
  position: relative;
  top: -20px;
  max-width: 775px;
  font-size: 14px;
  line-height: 150%;
  color: #000000;

  @media screen and (max-width: 768px) {
    top: -5px;
    margin-bottom: 15px;
  }

  &:deep {
    font-size: 14px;
    line-height: 150%;
    color: #000000;

    a {
      color: rgb(47, 87, 233);
      text-decoration: underline;
    }
  }
}

.content {
  height: 100%;
  display: grid;
  grid-template-columns: auto 430px;
  gap: 40px;

  @media screen and (max-width: 1440px) {
    grid-template-columns: auto;
    gap: 30px;
  }

  &__row-span {
    grid-row: span 2;
  }
}
</style>
