<template>
  <div class="ci__ui">
    <div
      class="input-container"
      :class="{
        error: errors.length > 0
      }"
      @click="$refs.input.focus()"
    >
      <input
        type="text"
        ref="input"
        :value="modelValue"
        :max="max"
        :maxlength="max"
        :readonly="!edit"
        :disabled="!edit"
        @focus="focus = true"
        @blur="focus = false"
        @input="$emit('update:modelValue', !edit ? modelValue : $event.target.value)"
      >

      <p
        class="input-container__placeholder"
        :class="{
          active: modelValue && modelValue.length > 0
        }"
      >
        {{ placeholder }}
      </p>

      <div
        v-if="errors.length > 0"
        class="error-icon"
      >
        <img src="@/static/svg/error-input-icon.svg">
      </div>
    </div>

    <template
      v-if="errors.length === 0 && focus"
    >
      <p
        v-for="(item, index) in info"
        :key="index"
        class="ci__ui__info-text"
      >
        {{ $t(item) }}
      </p>
    </template>

    <p
      v-for="(item, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(item) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    regExp: {
      type: RegExp,
      default: null
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    },
    info: {
      type: Array,
      default () {
        return []
      }
    },
    max: {
      type: Number,
      default: 256
    },
    replaceSymbol: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      focus: false
    }
  },
  watch: {
    modelValue () {
      if (this.regExp) {
        var value = this.modelValue
          .replace(this.regExp, '')
          .replace(/\s+/g, ' ')
          .replace(/--/g, '-')
          .replace(/ -/g, '-')

        if (this.replaceSymbol) {
          value = value.replace(this.replaceSymbol, '')
        }

        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
