<template>
  <div class="ci__ui">
    <div
      class="input-container"
      :class="{
        error: errors.length > 0
      }"
    >
      <input
        type="text"
        ref="input"
        :readonly="!edit"
        :disabled="!edit"
        :value="modelValue"
        @input="$emit('update:modelValue', !edit ? modelValue : $event.target.value)"
      >

      <p
        class="input-container__placeholder"
        :class="{
          active:
            _value?.length > 0 || isActive
        }"
      >
        {{ placeholder }}
      </p>
    </div>

    <p
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(error) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: true
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  mounted () {
    if (this.autoFocus) {
      this.$refs.input.focus()
      this._value = '+'
    }
  },
  computed: {
    _value: {
      get () {
        return String(this.modelValue)
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    modelValue () {
      var toParse = (this._value[0] === '+' ? this._value.slice(1) : this._value).replace(/\D/g, '')
      this._value = `+${toParse}`

      if (this._value.length > 20) {
        this._value = this._value.slice(0, 20)
      }
    }
  }
}
</script>

<style lang="scss">
.input-container {
  position: relative;
}

.vue-tel-input {
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: row-reverse;
  background: transparent;
  border: none;

  &.disabled {
    cursor: default;

    input {
      cursor: default;
    }
  }

  .vti__input {
    position: relative;
    top: 7px;
    background: transparent;
    padding: 0;
    color: rgb(var(--color-dark));
    font-size: 16px;
    line-height: 100%;
    font-family: Medium;
  }

  .vti__dropdown {
    padding: 0;
  }

  &:focus-within {
    box-shadow: none;
    border-color: none;
  }

  .vti__dropdown-list.below {
    top: 100%;
    // width: 100%;
    max-width: 250px;
    right: -8px !important;
    // left: -100% !important;
  }
}
</style>
