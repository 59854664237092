<template>
  <Container
    class="info"
    :class="{
      overflow: preloader
    }"
    :preloader="preloader"
  >
    <template #content>
      <div class="info__container personal">
        <p class="info__container__label">
          {{ $t('views.default.profile.personal-information') }}
        </p>

        <div class="info__container__grid">
          <InputText
            style="margin: 0;"
            :edit="false"
            :placeholder="`${$t('views.default.profile.name')}*`"
            :reg-exp="/[^a-z-A-Z-а-я-А-Я-ёЁ-їЇ -]/g"
            :errors="errors.firstname"
            v-model="user.firstname"
          />

          <InputText
            style="margin: 0;"
            :edit="false"
            :placeholder="`${$t('views.default.profile.surname')}*`"
            :reg-exp="/[^a-z-A-Z-а-я-А-Я-ёЁ-їЇ -]/g"
            :errors="errors.surname"
            v-model="user.surname"
          />

          <InputDate
            style="margin: 0;"
            :placeholder="$t('views.default.profile.date')"
            :errors="errors.birthday"
            v-model="user.birthday"
          />
        </div>
      </div>

      <div class="info__grid">
        <div class="info__container center">
          <p class="info__container__label">
            {{ $t('views.default.profile.contacts') }}
          </p>

          <div class="info__container__grid">
            <InputPhone
              style="margin: 0;"
              :edit="false"
              :placeholder="`${$t('views.auth.sign-up.phone')}*`"
              :errors="errors.phone"
              :auto-default-country="false"
              v-model="user.phone"
            />

            <InputText
              style="margin: 0;"
              :edit="false"
              :placeholder="`${$t('views.default.profile.email')}*`"
              :errors="errors.email"
              v-model="user.email"
            />
          </div>
        </div>

        <div class="info__container center">
          <p class="info__container__label">
            {{ $t('views.default.profile.location') }}
          </p>

          <div class="info__container__grid">
            <InputAutoComplete
              style="margin: 0;"
              :placeholder="$t('views.default.profile.country')"
              label="name"
              link="/private/countries?limit=100&offset=0"
              autoCompleteLink="/private/countries/autocomplete?limit=100&offset=0&q="
              :errors="[]"
              v-model="user.country_id"
            />

            <InputAutoComplete
              style="margin: 0;"
              :placeholder="$t('views.default.profile.city')"
              label="name"
              :disabled="!user.country_id"
              :link="`/private/cities?limit=100&offset=0&country_id=${user.country_id}`"
              :autoCompleteLink="`/private/cities/autocomplete?limit=100&offset=0&country_id=${user.country_id}&q=`"
              :errors="[]"
              v-model="user.city_id"
            />
          </div>
        </div>
      </div>

      <div class="info__buttons">
        <BlueButton
          :text="$t('views.default.profile.change-password')"
          @click="$pop.up('password')"
        />

        <BlueButton
          class="light"
          :text="$t('views.default.profile.save-changes')"
          :loading="loading"
          :disabled="loading"
          @click="update()"
        />
      </div>
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import InputText from '@/ui/inputs/Text.vue'
import InputDate from '@/ui/inputs/DatePicker.vue'
import InputPhone from '@/ui/inputs/Phone.vue'
import InputAutoComplete from '@/ui/inputs/AutoComplete.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const lodash = require('lodash')

export default {
  components: {
    Container,
    InputText,
    InputDate,
    InputPhone,
    InputAutoComplete,
    BlueButton
  },
  data () {
    return {
      v: useVuelidate(),
      loading: false,
      preloader: true,

      user: {},
      copyUser: {},

      errors: {
        phone: [],
        email: [],
        surname: [],
        firstname: [],
        birthday: []
      }
    }
  },
  validations () {
    return {
      user: {
        phone: { required },
        email: { required, email },
        surname: { required },
        firstname: { required }
      }
    }
  },
  mounted () {
    this.fetch()
  },
  deactivated () {
    this.user = this.copyUser
  },
  methods: {
    async fetch () {
      try {
        this.parseData(
          (await this.$axios.get('private/profile')).data.data.user)
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },
    async update () {
      this.loading = true
      this.v.user.$touch()
      this.setErrors()

      if (this._countError() === 0) {
        const formData = new FormData()
        formData.append('phone', this.user.phone)
        formData.append('surname', this.user.surname)
        formData.append('firstname', this.user.firstname)

        if (this.user.birthday) {
          formData.append('birthday', this.formatDate(this.user.birthday))
        }

        if (this.user.country_id) {
          formData.append('country_id', this.user.country_id)
        }

        if (this.user.city_id) {
          formData.append('city_id', this.user.city_id)
        }

        try {
          const { status } = await this.$axios.post(
            'private/profile', formData)

          if (status === 200) {
            this.$pop.up('successfully')
          }
        } catch (error) {
          if (error.messages?.phone && error.messages.phone[0] === 'The phone has already been taken.') {
            this.errors.phone.push('errors.phone-taken')
          }

          throw new Error(error)
        } finally {
          this.loading = false
        }
      }

      this.loading = false
    },
    setErrors () {
      const errorsForm = this.v.user
      this.errors = {
        phone: [],
        email: [],
        surname: [],
        firstname: [],
        birthday: []
      }

      // Phone
      if (errorsForm.phone.required.$invalid) {
        this.errors.phone.push('errors.required')
      }

      // Email
      if (errorsForm.email.required.$invalid) {
        this.errors.email.push('errors.required')
      }
      if (errorsForm.email.email.$invalid) {
        this.errors.email.push('errors.email')
      }

      // Surname
      if (errorsForm.surname.required.$invalid) {
        this.errors.surname.push('errors.required')
      }

      // Name
      if (errorsForm.firstname.required.$invalid) {
        this.errors.firstname.push('errors.required')
      }

      // Birthday
      if (this.user.birthday && typeof this.user.birthday === 'object') {
        const date = new Date().getFullYear() - this.user.birthday.getFullYear()

        if (date < 18) {
          this.errors.birthday.push('errors.age')
        }
      }
    },
    _countError () {
      const errors = this.errors
      var count = 0

      count += errors.phone.length +
        errors.email.length + errors.surname.length +
          errors.firstname.length + errors.birthday.length

      return count
    },
    parseData (data) {
      if (data.birthday) {
        data.birthday = new Date(data.birthday)
      }

      this.user = data
      this.copyUser = lodash.cloneDeep(data)
    },
    formatDate (date) {
      if (date) {
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)

        return `${ye}-${mo}-${da}`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  position: relative;
  width: 100%;
  padding: 30px 20px 20px 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
  border-radius: 15px;

  @media screen and (max-width: 768px) {
    padding: 20px 15px;
  }

  &.overflow {
    overflow: hidden;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
  }

  &__container {
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }

    &.personal {
      .info__container__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 15px;
        }
      }
    }

    &.payment {
      .info__container__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 15px;
        }
      }
    }

    &.center {
      margin: 0;

      &:last-child {
        @media screen and (max-width: 768px) {
          margin-top: 30px;
        }
      }

      .info__container__grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
      }
    }

    &__label {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 100%;
      color: rgba(var(--text-color-light-gray));
      font-family: SemiBold;

      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }

    button {
      max-width: 350px;

      @media screen and (max-width: 768px) {
        max-width: 100% !important;
        margin: 0 !important;
      }
    }
  }
}
</style>
