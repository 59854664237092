<template>
  <div class="advertising-campaigns">
    <h3>
      {{ $t('views.default.profile.advertising-campaigns.stats.title') }}
    </h3>

    <Container
      class="advertising-campaigns__container"
      :preloader="preloader"
    >
      <template #content>
        <div class="advertising-campaigns__container__stats">
          <div class="advertising-campaigns__container__stats__item">
            <div class="advertising-campaigns__container__stats__item__name">
              {{ $t('views.default.profile.advertising-campaigns.stats.campaigns-carried') }}
            </div>

            <h2>
              {{ value.count }}
            </h2>
          </div>

          <div class="advertising-campaigns__container__stats__item">
            <div class="advertising-campaigns__container__stats__item__name">
              {{ $t('views.default.profile.advertising-campaigns.stats.total-purchases') }}
            </div>

            <h2>
              ${{ $format.wallet(value.purchases) }}
            </h2>
          </div>

          <div class="advertising-campaigns__container__stats__item">
            <div class="advertising-campaigns__container__stats__item__name">
              {{ $t('views.default.profile.advertising-campaigns.stats.referral-bonus') }}
            </div>

            <h2>
              ${{ $format.wallet(value.referral) }}
            </h2>
          </div>
        </div>

        <div class="advertising-campaigns__container__buttons">
          <BlueButton
            :text="$t('views.default.profile.advertising-campaigns.stats.link-creation')"
            @click="$pop.up('creatingAdvertisingCampaign')"
          />

          <BlueButton
            tag="RouterLink"
            class="light"
            :to="{ name: 'AdvertisingСampaigns' }"
            :text="$t('views.default.profile.advertising-campaigns.stats.view-all')"
          />
        </div>
      </template>
    </Container>
  </div>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    Container,
    BlueButton
  },
  data () {
    return {
      preloader: true,
      value: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        this.value = (await this.$axios.get('private/advertising-campaigns/widget')).data.data
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.advertising-campaigns {
  h3 {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  &__container {
    padding: 20px 20px 30px;

    @media screen and (max-width: 768px) {
      padding: 15px;
    }

    &__stats {
      margin-bottom: 40px;
      padding: 10px 0;
      display: flex;
      background: #F0F2F8;
      border-radius: 10px;

      @media screen and (max-width: 1180px) {
        padding: 15px;
        flex-direction: column;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
        padding: 0 15px;
      }

      &__item {
        width: 100%;
        padding: 10px 20px;
        border-right: 1px solid rgba(156, 163, 184, .6);

        @media screen and (max-width: 1180px) {
          padding: 15px 0;
          border-right: none;
          border-bottom: 1px solid rgba(156, 163, 184, .6);
        }

        &:last-child {
          border-right: none;

          @media screen and (max-width: 1180px) {
            border-bottom: none;
          }
        }

        &__name {
          font-size: 16px;
          line-height: 100%;
          color: #202539;
          font-family: Medium;
        }

        h2 {
          margin-top: 30px;
          font-size: 32px;
          line-height: 100%;
          color: #202539;
          font-family: SemiBold;
        }
      }
    }

    &__buttons {
      display: flex;
      gap: 10px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      a, button {
        max-width: 350px;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
